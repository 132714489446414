import ApplicationController from "./application_controller.js";

export default class extends ApplicationController {
  static values = {
    name: String,
    action: String,
    controller: String,
    activity: String,
  };
  connect() {
    super.connect();
    // console.log("page connected");

    if (this.controllerValue != "activities") return;
    if (this.actionValue == "index") return;
    console.log(this.nameValue);
    document.querySelector(".js-action-name-tag").textContent = this.nameValue;
    document.querySelector("input.js-page").value = this.actionValue;
    if (this.activityValue != "index") return;
    this.stimulate(
      "notifications#open_conversation_action",
      this.activityValue,
      this.actionValue
    );
  }
}
