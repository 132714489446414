// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
Turbolinks.start();
ActiveStorage.start();
import * as $ from "jquery";
import * as tooltip from "jquery-ui/ui/widgets/tooltip";

import * as autocomplete from "jquery-ui/ui/widgets/autocomplete";
import icons from './icon.json'

require("trix");
require("@rails/actiontext");
import "controllers";
import select2 from "select2";
import "select2/dist/css/select2.css";
import "../custom/datetime-moment.js";
require("datatables.net-dt");

require("datatables.net-buttons-dt");
require("datatables.net-buttons/js/buttons.colVis.js");
require("datatables.net-responsive-dt");

require("datatables.net-dt/css/jquery.dataTables.css");
require("datatables.net-buttons-dt/css/buttons.dataTables.css");
require("datatables.net-responsive-dt/css/responsive.dataTables.css");
window.$ = $;
let a = true;

$(document).on("turbolinks:load", function () {
  $(document).ready(function () {
    // console.log("document ready");
    $(document).tooltip({ 
      content: function(callback) { 
         callback($(this)?.prop('title')?.replace('|', '<br />'))
         ?.replace('<b>', '<strong>')
         ?.replace('</b>', '</strong>'); 
      }
    });
    const autocomplete = $( "#category_icon" )
    if (autocomplete.length) {
      autocomplete.autocomplete({
        minLength: 2,
        source: icons.icons.map(i => i.name),
        
      })
      autocomplete.autocomplete( "instance" )._renderItem = 
     function( ul, item ) {
      console.log(item);
      return $( "<li>" )
        .append( "<div class='material-symbols-outlined'>" + item.value+"</div>" )
        .appendTo( ul );
    }
    }
    
    

    $.fn.dataTable.moment("DD/MM/yyyy");
    $("#users_table").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Hebrew.json",
      },
    });
    var targets = [1, 2, 3, 4, 5, 0];
    $("#activities_table thead tr")
      .clone(true)
      .addClass("filters hide")
      .appendTo("#activities_table thead");

    var activities_table = $("#activities_table").DataTable({
      dom: '<"row"<"col-8"f><"col-4"B>rtip>',
      stateSave: true,
      orderCellsTop: true,
      fixedHeader: true,
      buttons: [
        {
          extend: "collection",
          text: "סטטוס",
          className: "buttons-colvis btn btn-primary-cus",
          collectionLayout: "fixed two-column",
          buttons: [
            {
              text: "הכל",
              action: function (e, dt, node, config) {
                dt.columns().each((c) => dt.columns(c).search("").draw());
              },
            },
            {
              text: "בוצע",
              action: function (e, dt, node, config) {
                dt.columns(5).search("בוצע").draw();
              },
            },
            {
              text: "בעבודה",
              action: function (e, dt, node, config) {
                dt.columns(5).search("בעבודה").draw();
              },
            },
            {
              text: "נדחה",
              action: function (e, dt, node, config) {
                dt.columns(5).search("נדחה").draw();
              },
            },
            {
              text: "בוטל",
              action: function (e, dt, node, config) {
                dt.columns(5).search("בוטל").draw();
              },
            },
          ],
        },
        {
          className: "buttons-colvis btn btn-primary-cus",
          extend: "collection",
          text: "עמדות",
          buttons: ["columnsVisibility"],
          visibility: true,
        },
        {
          text: "חיפוש מתקדם",
          className: "buttons-colvis btn btn-primary-cus",
          action: function (e, dt, node, config) {
            dt.table()
              .header()
              .querySelector(".filters")
              .classList.toggle("hide");
          },
        },
        ,
      ],
      language: {
        url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Hebrew.json",
        buttons: {
          colvis: '<i class="icon icon-columns-solid"></i> עמודות',
          className: "btn btn-primary-cus",
        },
      },
      initComplete: function (settings, json) {
        var height = $(".buttons-colvis").outerHeight();
        $(".form-control").outerHeight(height);
        $(".buttons-colvis").removeClass("dt-button");
        $(".buttons-columnVisibility").addClass("btn-primary-cus");
        var api = this.api();
        // For each column
        api
          .columns()
          .eq(0)
          .each(function (colIdx) {
            // Set the header cell to contain the input element
            var cell = $(".filters th").eq(
              $(api.column(colIdx).header()).index()
            );
            var title = $(cell).text();
            // On every keypress in this input
            $(cell).html(
              '<input ע style="width:100%" type="text" placeholder="' +
                title +
                '" />'
            );

            // On every keypress in this input
            $(
              "input",
              $(".filters th ").eq($(api.column(colIdx).header()).index())
            )
              .off("keyup change")
              .on("change", function (e) {
                // Get the search value
                $(this).attr("title", $(this).val());
                var regexr = "({search})"; //$(this).parents('th').find('select').val();

                var cursorPosition = this.selectionStart;
                // Search the column for that value
                api
                  .column(colIdx)
                  .search(
                    this.value != ""
                      ? regexr.replace("{search}", "(((" + this.value + ")))")
                      : "",
                    this.value != "",
                    this.value == ""
                  )
                  .draw();
              })
              .on("keyup", function (e) {
                e.stopPropagation();

                $(this).trigger("change");
                $(this)
                  .focus()[0]
                  .setSelectionRange(cursorPosition, cursorPosition);
              });
          });
      },
    });

    // #column3_search is a <input type="text"> element
    $(".column-search").each(function (i, input) {
      $(input).on("change", function () {
        activities_table
          .columns(input.dataset.column)
          .search(this.value)
          .draw();
      });
    });

    $("#activities_table").on(
      "column-visibility.dt",
      function (e, settings, column, state) {
        // For each column
        activities_table
          .columns()
          .eq(0)
          .each(function (colIdx) {
            // Set the header cell to contain the input element
            var cell = $(".filters th").eq(
              $(activities_table.column(colIdx).header()).index()
            );
            var title = $(cell).text();
            // On every keypress in this input
            $(cell).html(
              '<input ע style="width:100%" type="text" placeholder="' +
                title +
                '" />'
            );

            // On every keypress in this input
            $(
              "input",
              $(".filters th ").eq(
                $(activities_table.column(colIdx).header()).index()
              )
            )
              .off("keyup change")
              .on("change", function (e) {
                // Get the search value
                $(this).attr("title", $(this).val());
                var regexr = "({search})"; //$(this).parents('th').find('select').val();

                var cursorPosition = this.selectionStart;
                // Search the column for that value
                activities_table
                  .column(colIdx)
                  .search(
                    this.value != ""
                      ? regexr.replace("{search}", "(((" + this.value + ")))")
                      : "",
                    this.value != "",
                    this.value == ""
                  )
                  .draw();
              })
              .on("keyup", function (e) {
                e.stopPropagation();

                $(this).trigger("change");
                $(this)
                  .focus()[0]
                  .setSelectionRange(cursorPosition, cursorPosition);
              });
          });
      }
    );

    const format = function (d, c) {
      // `d` is the original data object for the row
      let html = "";
      for (const [key, value] of Object.entries(d)) {
        if (c[key] && value.display) {
          html =
            html +
            "<tr>" +
            "<td>" +
            c[key].textContent +
            "</td>" +
            "<td>" +
            value.display +
            "</td>" +
            "</tr>" +
            "<tr>";
        }
      }

      return (
        '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
        html +
        "</table>"
      );
    };

    $("#activities_table tbody").on("click", "td.dt-control", function () {
      var tr = $(this).closest("tr");

      var row = activities_table.row(tr);
      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass("shown");
      } else {
        // Open this row
        row
          .child(
            format(
              row.data(),
              activities_table.columns(":not(.noVis)").header()
            )
          )
          .show();
        tr.addClass("shown");
      }
    });

    $("#cash_dashboard_table").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Hebrew.json",
        buttons: {
          colvis: '<i class="icon icon-columns-solid"></i> עמודות',
          className: "btn btn-primary-cus",
        },
      },
    });
  });
});
