// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];

  connect() {
    // console.log("connected to toggle_details_controller");
  }

  toggleDetails(e) {
    e = e || window.event;
    var target = e.target || e.srcElement || e;
    var toggle = target.dataset.toggle;
    if (target.dataset.toggle == "closed") {
      target.innerHTML = "לסגור הכל";
      target.dataset.toggle = "open";
      document.body
        .querySelectorAll("details")
        .forEach((e) => e.setAttribute("open", true));
    } else {
      target.innerHTML = "לפתוח הכל";
      target.dataset.toggle = "closed";
      document.body
        .querySelectorAll("details")
        .forEach((e) => e.removeAttribute("open"));
    }
  }
}
