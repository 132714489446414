// import { Controller } from "stimulus"
import ApplicationController from "./application_controller.js";
export default class extends ApplicationController {
  static targets = ["tabsHead", "btn", "tab"];
  connect() {
    // console.log("tabs connected");
    this.hide_tabs();
    this.show_tab(this.tabTargets[0].id);
  }
  switch(e) {
    this.hide_tabs();
    this.show_tab(e.currentTarget.dataset.tab);
  }
  show_tab(id) {
    this.tabTargets.find((e) => e.id == id).classList.remove("hide");
    this.btnTargets.find((e) => e.dataset.tab == id).classList.add("selected");
  }
  hide_tabs() {
    this.tabTargets.forEach((tab) => {
      tab.classList.add("hide");
    });
    this.btnTargets.forEach((tab) => {
      tab.classList.remove("selected");
    });
  }
}
