import ApplicationController from "./application_controller.js";

export default class extends ApplicationController {
  toggle_details(e) {
    const target = e.currentTarget;
    target.closest("tr").classList.toggle("more-details");
    // console.log(target.textContent);
    if (target.textContent == "+") target.textContent = "-";
    else if (target.textContent == "-") target.textContent = "+";
  }

  toggle_drill_down(e) {
    const target = e.currentTarget;
    const index = target.closest("tr").dataset.index;
    console.log(index);
    const table = target.closest("table");
    const trs = table.querySelectorAll("tr.js-details");
    console.log(target.querySelector("i").innerText);
    this.toggle_rows_by_condition(target, (tr) => {return index == tr.dataset.index1})

    this.set_drill_down_button_icon(target)
  }

  toggle_drill_down_all(e) {
    const target = e.currentTarget;
    this.toggle_rows_by_condition(target,(e) => {return true})
    this.set_drill_down_button_icon(target)
  }

  is_hide_tr(tr, index) {
    if (!tr.dataset.index1) return false;
    // console.log(tr, index, tr.dataset.index1);
  }

  set_drill_down_button_icon(target) {
    if (target.querySelector("i").innerText == "chevron_left")
    target.querySelector("i").innerText = "expand_more";
  else if (target.querySelector("i").innerText == "expand_more")
    target.querySelector("i").innerText = "chevron_left";
  }

  toggle_rows_by_condition(target,condition) {
    const table = target.closest("table");
    const trs = table.querySelectorAll("tr.js-details");
    // console.log(target.querySelector("i").innerText);
    const index = target.closest("tr").dataset.index;

    trs.forEach((tr) => {
      if (condition(tr)) {
        tr.classList.toggle("hide");
      }
    });
  }
}
