// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import StimulusReflex from "stimulus_reflex";
import consumer from "../channels/consumer";
import controller from "../controllers/application_controller";
import $ from "jquery";

$(document).on("turbolinks:load", function () {
  $(".select2").select2({
    placeholder: "בחרו...",
  });

  $(".select2").on("select2:select", function (e) {
    e.currentTarget.value = e.params.data.id;
    e.delegateTarget.dispatchEvent(new CustomEvent("change", { ...e }));
  });
  $(".select2-tags").select2({
    placeholder: "בחרו כמה...",
    width: "resolve",
    tags: true,
  });
  try {
    const alert = document.querySelector("#alert");
    alert.classList.add("alert");
    setTimeout(() => {
      alert.classList.remove("alert");
    }, 3000);

  } catch (error) {
    console.log(error);
  }
  try {
    const notice = document.querySelector("#notice");
    notice.classList.add("notice");
    setTimeout(() => {
      notice.classList.remove("notice");
    }, 3000);
  } catch (error) {
    console.log(error);

  }

});
$(document).on("turbolinks:request-start", function () {
  const app_status = document.querySelector(".app-status");
  app_status.textContent = "טוען דף...";
});

$(document).on("turbolinks:request-end", function () { });

$(document).on("turbolinks:load", function () {
  Array.from(document.querySelectorAll(".dirty")).forEach((e) =>
    e.classList.remove("dirty")
  );
  const app_status = document.querySelector(".app-status");

  app_status.textContent = "";
  if (app_status.closest(".bottom-footer").classList.contains("warning")) {
    app_status.textContent = "תקלה";
  }
});

const application = Application.start();
application.consumer = consumer;

const context = require.context("controllers", true, /_controller\.js$/);
application.load(definitionsFromContext(context));
StimulusReflex.initialize(application, { controller, isolate: true, debug: true });
StimulusReflex.debug = process.env.RAILS_ENV === "development";

if (process.env.RAILS_ENV === "development") {
  import("radiolabel").then((Radiolabel) =>
    application.register("radiolabel", Radiolabel.default)
  );
}
