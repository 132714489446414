import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    console.log("status controller");
  }

  update_dynamic_status(e) {
    console.log("dynamic");
  }

  update_status(e) {
    console.log("static");
  }

}
