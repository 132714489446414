import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["source", "price", "template", "kind"];
  sum(e) {
    const tr = e.target.closest("tr");
    const destroy = tr.querySelector("input[name*='destroy']");
    const unit_price = parseFloat(tr.querySelector(".unit_price").innerHTML);
    const quantity = e.target.value;
    const price = unit_price * quantity;
    const mifal = e.target.dataset.mifal;
    const price_input = tr.querySelector(
      `input[name*='price'][data-mifal='${mifal}']`
    );
    price_input.value = price;
    if (quantity == 0) {
      destroy.value = true;
      // console.log("quantity 0");
    } else {
      destroy.value = false;
    }
    this.sum_source(mifal);
  }
  // כרגע עובר על כל החפצים, אולי כדאי בהמשך בשבילך מהירות לעשות שזה יחשב אחד אחד
  sum_source(mifal) {
    const source_input = this.sourceTargets.find(
      (e) => e.dataset.mifal == mifal
    );
    source_input.value = 0;
    let value = 0;
    for (var i = 0; i < this.priceTargets.length; i++) {
      if (this.priceTargets[i].value != "") {
        value = parseFloat(value) + parseFloat(this.priceTargets[i].value);
      }
    }
    source_input.value = value;
  }

  new(e) {
    if (e.target.value == "אחר") {
      content = this.templateTarget.innerHTML;
      // const added = this.kindTarget.insertAdjacentHTML('beforebegin', content)
      e.target.name = "original_food_kind";
      this.kindTarget.innerHTML = content;
    } else {
      this.kindTarget.innerHTML = "";
      e.target.name = "food[kind]";
    }
  }
}
