import ApplicationController from './application_controller.js'

export default class extends ApplicationController {
	update_user_level(event) {
    const user_id = event.target.dataset.userId
    const level = event.target.value
    this.stimulate('User#update_user_level', user_id, level)
  }
	update_user_ezor(event) {
    const user_id = event.target.dataset.userId
    const ezor = event.target.value
    this.stimulate('User#update_user_ezor', user_id, ezor)
  }
}
