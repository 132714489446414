import ApplicationController from "../application_controller.js";

export default class extends ApplicationController {
  static targets = ["input", "label","image"];
  connect() {
    super.connect();
    console.log(this.hasInputTarget );
    if (this.hasInputTarget ) {
        this.inputTarget.addEventListener("change", (e) => this.change_input_text_content())
    }

  }

  change_input_text_content() {
    var tmppath = URL.createObjectURL(this.inputTarget.files[0]);
    console.log(tmppath);
    console.log(this.inputTarget.files[0]);
    this.imageTarget.src = tmppath
  }


}
