// import { Controller } from "stimulus"
import ApplicationController from "./application_controller.js";
import $ from "jquery";

let tr;
let quantity;
let unit_price;
let mifal;

export default class extends ApplicationController {
  static targets = [
    "hello",
    "total",
    "totalExpence",
    "totalIncome",
    "totalSpending",
    "btn",
    "pricingTemplate",
    "incomeTemplate",
    "requirmentsTemplate",
    "newRequirment",
    "kidsTotal",
    "guidesTotal",
    "headsTotal",
    "mifalTotal",
    "mifalTotalIncome",
    "mifalTotalExpence",
    "kidsExpence",
    "guidesExpence",
    "headsExpence",
    "mifalTotalSpending",
    "kidsExpenceSpending",
    "guidesExpenceSpending",
    "headsExpenceSpending",
    "kidsIncomeSpending",
    "guidesIncomeSpending",
    "headsIncomeSpending",
    "kidsIncome",
    "guidesIncome",
    "headsIncome",
    "kidsTotalExpence",
    "guidesTotalExpence",
    "headsTotalExpence",
    "kidsTotalSpending",
    "guidesTotalSpending",
    "headsTotalSpending",
    "kidsTotalIncome",
    "guidesTotalIncome",
    "headsTotalIncome",
    "kidsHanhaga",
    "guidesHanhaga",
    "headsHanhaga",
    "form",
  ];

  connect() {
    super.connect();
    if (this.hasTotalTarget) {
      const mifalKinds = ["kids", "guides", "heads"];
      mifalKinds.forEach((mifalKind) => {
        if (this[`has${super.capitalize(mifalKind)}TotalTarget`]) {
          // console.log(this[`has${super.capitalize(mifalKind)}TotalExpenceTarget`]);
          this[`${mifalKind}ExpenceTargets`].forEach((input) => {
            this.quantity_sum(null, input, true);
          });
          this[`${mifalKind}IncomeTargets`].forEach((input) => {
            this.quantity_sum(null, input, true);
          });
          this.sum_mifal_total(null, mifalKind);
        }
      });
    }
    if (this.hasFormTarget) {
      const body = document.body,
        html = document.documentElement;
      const height = Math.min(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      this.formTarget.style.height = height + "px";
    }
  }

  toFloat(num) {
    return parseFloat(num.toFixed(2));
  }
  show_sources() {
    for (var i = 0; i < this.existingTargets.length; i++) {
      this.existingTargets[i];
      this.stimulate(
        "Pricing#show_source",
        this.existingTargets[i].dataset.source,
        this.existing_templateTarget.innerHTML
      );
    }
  }
  quantity_sum(e, input, bulk = false) {
    if (e == null) {
      tr = input.closest("tr");
      quantity = tr.querySelector(".quantity").value;
      unit_price = parseFloat(tr.querySelector(".unit_price").value);
      mifal = input.dataset.mifal;
    } else {
      tr = e.target.closest("tr");
      quantity = tr.querySelector(".quantity").value;
      unit_price = parseFloat(tr.querySelector(".unit_price").value);
      mifal = e.target.dataset.mifal;
    }
    const destroy = tr.querySelector("input[name*='destroy']");
    if (isNaN(unit_price)) {
      unit_price = parseFloat(tr.querySelector(".unit_price").innerHTML);
    }
    const price = unit_price * quantity;
    const price_input = tr.querySelector(".price");
    if (!isNaN(price)) price_input.value = this.toFloat(price);
    if (quantity == 0) {
      destroy.value = true;
    } else {
      destroy.value = false;
    }
    if (this.hasTotalTarget && !bulk) {
      this.sum_mifal_total(e);
    }
    this.check_requrment(e);
  }

  // avg_price_sum(e) {
  //         if (e == null) {
  //             tr = input.closest('tr')
  //             unit_price = input.value
  //             mifal = input.dataset.mifal
  //         } else {
  //             tr = e.target.closest('tr')
  //             unit_price = e.target.value
  //             mifal = e.target.dataset.mifal
  //         }
  //         const destroy = tr.querySelector("input[name*='destroy']")
  //         let quantity = parseFloat(tr.querySelector(".activity_sources_mifal_sources_quantity input").value)
  //         const price = unit_price * quantity
  //         const price_input = tr.querySelector(`input[name*='price'][data-mifal='${mifal}']:not([name*='avg'])`)
  //         price_input.value = price
  //         if (quantity == 0) {
  //             destroy.value = true
  //         } else {
  //             destroy.value = false
  //         }
  //         if (this.hasTotalTarget) {
  //             // this.sum_total()
  //             this.source_total(tr)
  //         }
  //     }
  // כרגע עובר על כל החפצים, אולי כדאי בהמשך בשבילך מהירות לעשות שזה יחשב אחד אחד
  sum_total() {
    this.totalTarget.value = 0;
    let expence = 0;
    let income = 0;
    let spending = 0;
    for (var i = 0; i < this.mifalTotalExpenceTargets.length; i++) {
      if (this.mifalTotalExpenceTargets[i].value != "") {
        expence =
          parseFloat(expence) +
          parseFloat(this.mifalTotalExpenceTargets[i].value);
      }
    }
    for (var i = 0; i < this.mifalTotalIncomeTargets.length; i++) {
      if (this.mifalTotalIncomeTargets[i].value != "") {
        income =
          parseFloat(income) +
          parseFloat(this.mifalTotalIncomeTargets[i].value);
      }
    }
    for (var i = 0; i < this.mifalTotalSpendingTargets.length; i++) {
      if (this.mifalTotalSpendingTargets[i].value != "") {
        spending =
          parseFloat(spending) +
          parseFloat(this.mifalTotalSpendingTargets[i].value);
      }
    }
    this.totalExpenceTarget.value = this.toFloat(expence);
    this.totalIncomeTarget.value = this.toFloat(income);
    this.totalSpendingTarget.value = this.toFloat(spending);
    this.totalTarget.value = this.toFloat(income - expence);
  }

  sum_mifal_total(e, mifalKind) {
    if (e == null) {
      mifalKind = mifalKind;
    } else {
      mifalKind = e.target.dataset.mifalKind;
    }
    this[mifalKind + "TotalTarget"].value = 0;
    let expence = 0;
    let spending = 0;
    let income = 0;
    let hanhaga = 0;
    for (var i = 0; i < this[mifalKind + "ExpenceTargets"].length; i++) {
      if (this[mifalKind + "ExpenceTargets"][i].value != "") {
        expence =
          parseFloat(expence) +
          parseFloat(this[mifalKind + "ExpenceTargets"][i].value);
      }
    }
    for (
      var i = 0;
      i < this[mifalKind + "ExpenceSpendingTargets"].length;
      i++
    ) {
      if (this[mifalKind + "ExpenceSpendingTargets"][i].value != "") {
        spending =
          parseFloat(spending) -
          parseFloat(this[mifalKind + "ExpenceSpendingTargets"][i].value);
      }
    }
    for (var i = 0; i < this[mifalKind + "IncomeSpendingTargets"].length; i++) {
      if (this[mifalKind + "IncomeSpendingTargets"][i].value != "") {
        spending =
          parseFloat(spending) +
          parseFloat(this[mifalKind + "IncomeSpendingTargets"][i].value);
      }
    }
    for (var i = 0; i < this[mifalKind + "IncomeTargets"].length; i++) {
      if (this[mifalKind + "IncomeTargets"][i].value != "") {
        income =
          parseFloat(income) +
          parseFloat(this[mifalKind + "IncomeTargets"][i].value);
      }
    }
    hanhaga = expence * 0.15;
    expence = expence + hanhaga;
    this[mifalKind + "TotalIncomeTarget"].value = this.toFloat(income);
    this[mifalKind + "HanhagaTarget"].value = this.toFloat(hanhaga);
    //console.log(this.kidsTotalSpendingTarget);
    this[mifalKind + "TotalExpenceTarget"].value = this.toFloat(expence);
    this[mifalKind + "TotalSpendingTarget"].value = this.toFloat(spending);

    this[mifalKind + "TotalTarget"].value = this.toFloat(income - expence);

    this.sum_total();
  }

  // source_total(tr) {
  //     const all_prices = tr.querySelectorAll("input[name*='price']:not([name*='avg_price'])")
  //     let total_price = 0
  //     for (var i = 0; i < all_prices.length; i++) {
  //         if (all_prices[i].value != '') {
  //             total_price = parseFloat(total_price) + parseFloat(all_prices[i].value)
  //         }
  //     }
  //     // tr.querySelector("td[data-total]").innerHTML = total_price
  //     this.sum_total()
  // }

  new(e) {
    const content = this.incomeTemplateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    );
    e.target
      .closest("table")
      .querySelector(`[data-btn].${e.target.dataset.budgetKind}`)
      .insertAdjacentHTML("beforeBegin", content);
  }
  remove(e) {
    e.preventDefault();
    if (window.confirm("בטוחה?")) {
      const tr = e.target.closest("tr");
      const destroy = tr.querySelector(
        "input[name*='destroy']:not([name*='mifal_sources_attributes'])"
      );
      destroy.value = true;
      tr.style.display = "none";
    }
  }

  select_new_source(e) {
    const target = e.currentTarget;
    const tr = target.closest("tr");
    const { mifal, source, activity, index } = {
      ...target.closest("tr").dataset,
      source: target.value,
    };
    // console.log(mifal, source, activity);
    this.stimulate(
      "Sources#select_new_source",
      mifal,
      source,
      activity,
      tr.id,
      index
    );
  }

  add_selected_sources(e) {
    // console.log(e.target.dataset);
    const table = document.getElementById(e.target.dataset.table);
    const trs = Array.from(table.querySelector("tbody").querySelectorAll("tr"));
    // console.log(trs);

    const selected = trs.filter(
      (tr) => tr.classList.contains("selected")
    );
    // console.log(selected);
    const sources_to_add = [];
    selected.forEach((tr) => {
      sources_to_add.push({
        row: tr.dataset.row,
        mifal: tr.dataset.mifal,
        activity: tr.dataset.activity,
        source: tr.dataset.source,
        quantity:  tr.querySelector('input.amount-js').value,
        index: tr.dataset.index,
      });
    });
    // console.log(sources_to_add);
    const missing = parseInt(
      document.querySelector(".missing_sources_num-js").getAttribute("badge"),
      10
    );
    // console.log(sources_to_add);
    // console.log(sources_to_add.map((e) => parseInt(e.quantity, 10)));

    document
      .querySelector(".missing_sources_num-js")
      .setAttribute(
        "badge",
        missing -
          sources_to_add.filter((e) => parseInt(e.quantity, 10) > 0).length
      );
    this.stimulate("sources#select_new_sources", sources_to_add).then(() => {
      document.querySelectorAll(".modal-wrapper").forEach((modal) => {
        modal.classList.add("hide");
      });
    });
  }

  add_requirment(event) {
    event.preventDefault();
    const t = this.requirmentsTemplateTarget;
    var content = t.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
    const added = this.newRequirmentTarget.insertAdjacentHTML(
      "beforebegin",
      content
    );
  }

  check_requrment(e) {
    const tr = e.target.closest("tr");
    const requierd = tr.dataset.required;
    if (!requierd) return;
    tr.querySelector(".js-requierment-btn").dataset.current = parseInt(
      requierd,
      10
    );
    this.check_if_requrment_fullfiled(e);
  }

  check_if_requrment_fullfiled(e) {
    const tr = e.target.closest("tr");
    const quantity = tr.querySelector(".quantity").value;
    const requierd = tr.dataset.required;
    // console.log(quantity, requierd, parseInt(requierd, 10));
    const missing = parseInt(requierd, 10) - parseInt(quantity, 10);
    const is_missing = missing > 0;
    const is_fullfiled = !is_missing;
    tr.classList.toggle("warning", is_missing);
    tr.querySelector(".js-requierment-btn i").textContent = is_missing
      ? "info"
      : "check";
      tr.querySelector(".js-requierment-btn").classList.toggle("delete-btn",is_missing)
      tr.querySelector(".js-requierment-btn").classList.toggle("success-btn",is_fullfiled)
  }

  filter_required(e) {
    const table = document.getElementById(e.target.dataset.table);
    const trs = Array.from(table.querySelector("tbody").querySelectorAll("tr"));
    // console.log(trs);
    trs.forEach((tr) => {
      if (e.target.dataset.filter == "all" || tr.dataset.filter == "missing")
        tr.style.display = "table-row";
      else tr.style.display = "none";
    });
  }

  filter_mifal(e) {
    const table = document.getElementById(e.currentTarget.dataset.table);
    const trs = Array.from(table.querySelector("tbody").querySelectorAll("tr"));
    const mifal = e.currentTarget.dataset.filter;
    // console.log(trs);
    trs.forEach((tr) => {
      if (tr.dataset.mifal == mifal) tr.style.display = "table-row";
      else tr.style.display = "none";
    });
  }

  filter_category(e) {
    const table = document.getElementById(e.currentTarget.dataset.table);
    const trs = Array.from(table.querySelector("tbody").querySelectorAll("tr"));
    const category = e.currentTarget.value;
    // console.log(table);
    trs.forEach((tr) => {
      if (
        category == "all" ||
        !tr.dataset.category ||
        tr.dataset.category == category
      )
        tr.style.display = "table-row";
      else tr.style.display = "none";
    });
  }
}
