import ApplicationController from "./application_controller.js";

export default class extends ApplicationController {
  static targets = ["source", "price", "add", "template"];

  sum(e) {
    const tr = e.target.closest("tr");
    const destroy = tr.querySelector("input[name*='destroy']");
    const unit_price = tr.querySelector(".unit_price").value;
    const quantity = tr.querySelector(".quantity").value;
    const price = unit_price * quantity;
    const mifal = e.target.dataset.mifal;
    // const price_input = tr.querySelector(`input[name*='price'][data-mifal='${mifal}']`)
    const price_input = tr.querySelector(".price");
    price_input.value = price;
    if (quantity == 0) {
      destroy.value = true;
      // console.log("quantity 0");
    } else {
      destroy.value = false;
    }
    //this.sum_source(mifal);
    this.check_requrment(e);
  }
  // כרגע עובר על כל החפצים, אולי כדאי בהמשך בשבילך מהירות לעשות שזה יחשב אחד אחד
  sum_source(mifal) {
    const source_input = this.sourceTargets.find(
      (e) => e.dataset.mifal == mifal
    );
    source_input.value = 0;
    let value = 0;
    for (var i = 0; i < this.priceTargets.length; i++) {
      if (this.priceTargets[i].value != "") {
        value = parseFloat(value) + parseFloat(this.priceTargets[i].value);
      }
    }
    source_input.value = value;
  }

  check_requrment(e) {
    const tr = e.target.closest("tr");
    const requierd = tr.dataset.required;
    tr.querySelector(".js-requierment-btn").dataset.current = parseInt(
      e.currentTarget.value,
      10
    );
    this.check_if_requrment_fullfiled(e);
  }

  check_if_requrment_fullfiled(e) {
    const tr = e.target.closest("tr");
    const quantity = tr.querySelector(".quantity").value;
    const requierd = tr.dataset.required;
    // console.log(quantity, requierd, parseInt(requierd, 10));
    const missing = parseInt(requierd, 10) - parseInt(quantity, 10);
    const is_missing = missing > 0;
    const is_fullfiled = !is_missing;
    tr.classList.toggle("warning", is_missing);
    tr.querySelector(".js-requierment-btn i").textContent = is_missing
      ? "info"
      : "check";
      tr.querySelector(".js-requierment-btn").classList.toggle("delete-btn",is_missing)
      tr.querySelector(".js-requierment-btn").classList.toggle("success-btn",is_fullfiled)
  }

  fullfill_requrment(e) {
    const tr = e.target.closest("tr");
    const requierd = tr.querySelector(".fifty_quantity").dataset.required;
    tr.querySelector(".quantity").value = parseInt(requierd, 10);
    this.check_if_requrment_fullfiled(e);
  }

  fullfill_all_requrment(e) {}

  add_item(event) {
    event.preventDefault();
    const t = this.templateTargets.filter(
      (t) => t.dataset.mifal == event.target.dataset.mifal
    )[0];
    var content = t.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
    const added = this.addTarget.insertAdjacentHTML("beforebegin", content);
  }

  select_new_item(e) {
    const target = e.currentTarget;
    const tr = target.closest("tr");
    const { mifal, item, activity, index } = {
      ...target.closest("tr").dataset,
      item: target.value,
    };
    // console.log(mifal, item, activity);
    this.stimulate(
      "items#select_new_item",
      mifal,
      item,
      activity,
      tr.id,
      index
    );
  }

  add_selected_items(e) {
    e.preventDefault();
    // console.log(e.target.dataset);
    const table = document.getElementById(e.target.dataset.table);
    const trs = Array.from(table.querySelector("tbody").querySelectorAll("tr"));
    // console.log(trs);

    const selected = trs.filter(
      (tr) =>
        tr.classList.contains("selected") &&
        tr.offsetParent !== null
    );
    // console.log(selected);
    if (selected.length == 0) return;
    const items_to_add = [];
    selected.forEach((tr) => {
      items_to_add.push({
        row: tr.dataset.row,
        mifal: tr.dataset.mifal,
        activity: tr.dataset.activity,
        item: tr.dataset.item,
        index: tr.dataset.index,
        amount: tr.querySelector('input.amount-js').value
      });
    });
    // console.log(items_to_add);
    const missing = parseInt(
      document.querySelector(".missing_items_num-js").getAttribute("badge"),
      10
    );
    document
      .querySelector(".missing_items_num-js")
      .setAttribute("badge", missing - selected.length);
    this.stimulate("items#select_new_items", items_to_add);
  }

  filter_required(e) {
    const table = document.getElementById(e.target.dataset.table);
    const trs = Array.from(table.querySelector("tbody").querySelectorAll("tr"));
    // console.log(trs);
    trs.forEach((tr) => {
      if (e.target.dataset.filter == "all" || tr.dataset.filter == "missing")
        tr.style.display = "table-row";
      else tr.style.display = "none";
    });
  }

  filter_mifal(e) {
    const table = document.getElementById(e.currentTarget.dataset.table);
    const trs = Array.from(table.querySelector("tbody").querySelectorAll("tr"));
    const mifal = e.currentTarget.dataset.filter;
    // console.log(trs);
    trs.forEach((tr) => {
      if (tr.dataset.mifal == mifal) tr.style.display = "table-row";
      else tr.style.display = "none";
    });
  }

  filter_category(e) {
    const table = document.getElementById(e.currentTarget.dataset.table);
    const trs = Array.from(table.querySelector("tbody").querySelectorAll("tr"));
    const category = e.currentTarget.value;
    // console.log(category);
    trs.forEach((tr) => {
      if (
        category == "all" ||
        !tr.dataset.category ||
        tr.dataset.category == category
      )
        tr.style.display = "table-row";
      else tr.style.display = "none";
    });
  }
}
