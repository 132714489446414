import ApplicationController from "./application_controller.js";

import Cookies from "js-cookie";

export default class extends ApplicationController {
  // static targets = [ "output" ]
  initialize() {
    this.cookie = Cookies.get("nav");
  }
  connect() {
    super.connect();
    if (this.isActionCableConnectionOpen()) {
      const that = this;
      setTimeout(function () {
        console.log("notifications#set_only_notification_number");
        that.stimulate("notifications#set_only_notification_number");
      }, 100);
    }
  }
  toggleNav(e) {
    const nav = document.querySelector(".side-nav");
    const main = document.querySelector("main");
    if (nav.classList.contains("nav-open")) {
      nav.classList.remove("nav-open");
      main.classList.remove("nav-open");
      Cookies.set("nav", "close");
    } else {
      nav.classList.add("nav-open");
      main.classList.add("nav-open");
      Cookies.set("nav", "open");
    }
  }
}
