import CableReady from "cable_ready";
import ApplicationController from "./application_controller.js";

export default class extends ApplicationController {
  static values = { id: Number, partial: String };

  connect() {
    // console.log("connect conversation");
    super.connect();

    document
      .getElementById("notificationsWrapper")
      .addEventListener("cable-ready:after-morph", this.scrollToTop());
    this.scrollToTop();

    this.channel = this.application.consumer.subscriptions.create(
      {
        channel: "ConversationsChannel",
        id: this.idValue,
        partial: this.partialValue,
      },
      {
        received(data) {
          if (data.cableReady) {
            CableReady.perform(data.operations);
          }
        },
      }
    );
  }
  scrollToTop() {
    var myDiv = document.getElementById("notifications-masseges");
    myDiv.scrollTop = myDiv.scrollHeight * 3;
  }

  disconnect() {
    this.channel.unsubscribe();
  }
}
