import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import Cookies from "js-cookie";
import $ from "jquery";
import Select2 from "select2";

/* This is your ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
    // console.log("ApplicationController connected");

    
  }

  capitalize(str) {
    str = str.charAt(0).toUpperCase() + str.slice(1);
    return str;
  }

  /* Application-wide lifecycle methods
   *
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Example#demo"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  beforeReflex(element, reflex, noop, reflexId) {
    // document.body.classList.add('wait')
    // console.log("before");
    const status = document.querySelector(".app-status")
    if (status.getAttribute("is-reflex") != "true")
    {status.setAttribute("text-before",status.textContent)}
    status.setAttribute("is-reflex","true")

    status.textContent = `working on ${reflex}`;
    // console.log(reflex);
    if (reflex == "User#update_user_level") {
      const name = element
        .closest("tr")
        .querySelector("td:first-of-type").innerHTML;
      alert("המשתמש של " + name + " עודכן בהצלחה");
    }
  }

  reflexSuccess(element, reflex, noop, reflexId) {
    // console.log("reflexSuccess");

    // console.log(reflex);

    const reflexes = [
      "status#update_mifal_status",
      "status#update_note",
      "Food#update_menus",
    ];
    if (reflexes.includes(reflex)) {
      try {
        toast = document.querySelector("#toast");
        toast.innerHTML = "updated";
        toast.classList.add("toast");
        setTimeout(() => {
          toast.classList.remove("toast");
        }, 1500);
      } catch (error) {
        console.log(error);
      }
     
    }
  }

  reflexError(element, reflex, error, reflexId) {
    // console.log(reflex);
    // show error message
    document.querySelector("div.loading").classList.remove("show");
    document.querySelector(".app-status").textContent = document.querySelector(".app-status").getAttribute("text-before");
    document.querySelector(".app-status").setAttribute("is-reflex","true")
    console.log(error);
    try {
      const toast = document.querySelector("#alert");
      toast.textContent = `${reflex} ${error.split("<")[0]}`;
      toast.classList.add("alert");
      setTimeout(() => {
        toast.classList.remove("alert");
      }, 6000);
    } catch (error) {
      console.log(error);
    }
  }

  reflexHalted(element, reflex, error, reflexId) {
    // console.log(reflex);
    // handle aborted Reflex action
  }

  afterReflex(element, reflex, noop, reflexId) {
    // console.log("after");
    // console.log(reflex);
    // document.body.classList.remove('wait')
    document.querySelector(".app-status").textContent = document.querySelector(".app-status").getAttribute("text-before");
    document.querySelector(".app-status").setAttribute("is-reflex","true");

    if(reflex == "items#select_new_item")
    {
      $(".select2-tags").select2({
        placeholder: "בחרו...",
      });
    }
  }

  finalizeReflex(element, reflex, noop, reflexId) {
    // console.log("finalize");
    // console.log(reflex);
    
    // all operations have completed, animation etc is now safe
    if (reflex == "staffs#add_access") {
      $(".select2-tags").select2({
        placeholder: "בחרו...",
      });
    }

    if (
      reflex == "managment#more_info" ||
      reflex == "managment#activity_more_info"
    ) {
      var activities_table = $("#activities_table").DataTable({
        dom: '<"row"<"col-8"f><"col-4"B>rtip>',
        stateSave: true,
        orderCellsTop: true,
        fixedHeader: true,

        buttons: [
          {
            extend: "collection",
            text: "סטטוס",
            className: "buttons-colvis btn btn-primary-cus",
            collectionLayout: "fixed two-column",
            buttons: [
              {
                text: "הכל",
                action: function (e, dt, node, config) {
                  dt.columns().each((c) => dt.columns(c).search("").draw());
                },
              },
              {
                text: "בוצע",
                action: function (e, dt, node, config) {
                  dt.columns(5).search("בוצע").draw();
                },
              },
              {
                text: "בעבודה",
                action: function (e, dt, node, config) {
                  dt.columns(5).search("בעבודה").draw();
                },
              },
              {
                text: "נדחה",
                action: function (e, dt, node, config) {
                  dt.columns(5).search("נדחה").draw();
                },
              },
              {
                text: "בוטל",
                action: function (e, dt, node, config) {
                  dt.columns(5).search("בוטל").draw();
                },
              },
            ],
          },
          {
            className: "buttons-colvis btn btn-primary-cus",
            extend: "collection",
            text: "עמדות",
            buttons: ["columnsVisibility"],
            visibility: true,
          },
          {
            text: "חיפוש מתקדם",
            className: "buttons-colvis btn btn-primary-cus",
            action: function (e, dt, node, config) {
              dt.table()
                .header()
                .querySelector(".filters")
                .classList.toggle("hide");
            },
          },
          ,
        ],
        language: {
          url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Hebrew.json",
          buttons: {
            colvis: '<i class="icon icon-columns-solid"></i> עמודות',
            className: "btn btn-primary-cus",
          },
        },
        initComplete: function (settings, json) {
          var height = $(".buttons-colvis").outerHeight();
          $(".form-control").outerHeight(height);
          $(".buttons-colvis").removeClass("dt-button");
          $(".buttons-columnVisibility").addClass("btn-primary-cus");
          var api = this.api();
          // For each column
          api
            .columns()
            .eq(0)
            .each(function (colIdx) {
              // Set the header cell to contain the input element
              var cell = $(".filters th").eq(
                $(api.column(colIdx).header()).index()
              );
              var title = $(cell).text();
              // On every keypress in this input
              $(cell).html(
                '<input ע style="width:100%" type="text" placeholder="' +
                  title +
                  '" />'
              );

              // On every keypress in this input
              $(
                "input",
                $(".filters th ").eq($(api.column(colIdx).header()).index())
              )
                .off("keyup change")
                .on("change", function (e) {
                  // Get the search value
                  $(this).attr("title", $(this).val());
                  var regexr = "({search})"; //$(this).parents('th').find('select').val();

                  var cursorPosition = this.selectionStart;
                  // Search the column for that value
                  api
                    .column(colIdx)
                    .search(
                      this.value != ""
                        ? regexr.replace("{search}", "(((" + this.value + ")))")
                        : "",
                      this.value != "",
                      this.value == ""
                    )
                    .draw();
                })
                .on("keyup", function (e) {
                  e.stopPropagation();

                  $(this).trigger("change");
                  $(this)
                    .focus()[0]
                    .setSelectionRange(cursorPosition, cursorPosition);
                });
            });
        },
      });
    }
  }
  finalizeTogglePrep(element, reflex, noop, reflexId) {
    if (Cookies.get("nav") == "open") {
      document.querySelector("main").classList.add("nav-open");
    }
    $(".select2").select2({
      placeholder: "בחרו...",
    });

    $(".select2-tags").select2({
      placeholder: "בחרו...",
    });

    $(".select2").on("select2:select", function (e) {
      // console.log(e);
    });
  }

  finalizeOpenConversations(element, reflex, noop, reflexId) {}

  finalizeOpenConversation(element, reflex, noop, reflexId) {}

  test() {
    let sum = 0;
    // console.log("countBadges");
    var myDiv = document.getElementById("notificationsWrapper");
    myDiv.querySelectorAll("[badge]").forEach((e) => {
      sum += parseInt(e.getAttribute("badge"), 10);
    });
    // console.log(sum);
    document.getElementById("notification-sum").setAttribute("badge", sum);
  }
}
