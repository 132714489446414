import ApplicationController from "../application_controller.js";

export default class extends ApplicationController {
  connect() {
    super.connect();
    console.log("save")
const favDialog = document.getElementById('favDialog');


// "Confirm" button of form triggers "close" on dialog because of [method="dialog"]
    favDialog.addEventListener('close', () => {
        if (favDialog.returnValue.includes("save")) this.formSubmit(favDialog.getAttribute('form'))
        if (favDialog.returnValue.includes("change status")) this.changeStatus(favDialog.getAttribute('form'))
    });

  }

  save(e) {
    console.log("save");
    e.preventDefault()
    favDialog.setAttribute('form',e.target.getAttribute('form'))
    favDialog.showModal();
  }

  formSubmit(id) {
    document.getElementById(id).submit();
}
changeStatus(id) {
    console.log(id);
    console.log("status ready");
}

}