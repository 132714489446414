import { Controller } from "stimulus";
import DirtyForm from "dirty-form";

export default class extends Controller {
  connect() {
    // new DirtyForm(this.element, {
    //   message: 'יש לך שינויים שלא נשמרו, באמת לעבור לדף אחר?',
    // })
    const f = new DirtyForm(this.element, {
      message: "יש לך שינויים שלא נשמרו, באמת לעבור לדף אחר?",
    });
    this.element.addEventListener("change", (e) => {
      console.table(f.isDirty);
      if (f.isDirty)
        document.querySelector(".app-status").textContent =
          "יש שינויים שלא נשמרו";

      document
        .querySelector(".bottom-footer")
        .classList.toggle("dirty", f.isDirty);
      document.querySelector(".bottom-footer").classList.remove("warning");
    });
    this.element.addEventListener("submit", (e) => {
      document.querySelector("div.loading").classList.add("show");
    });
  }

  submit(e) {}
}
