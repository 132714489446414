import CableReady from "cable_ready";
import consumer from "./consumer"

consumer.subscriptions.create("PdfReadyChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("pdf_ready");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if (data.cableReady) CableReady.perform(data.operations)
  }
})