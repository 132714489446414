import ApplicationController from "./application_controller.js";
import $ from "jquery";

export default class extends ApplicationController {
  static targets = ["fileKids", "fileGuides", "fileHeads"];
  connect() {
    super.connect();
  }
  finalizeAddAttachment(element) {
    if (element.files[0].type != "application/pdf") {
      element.value = "";
    }
  }
  beforeRemoveAttachment(element) {
    const mifalType = element.dataset.mifalType;
    this[`file${super.capitalize(mifalType)}Target`].value = "";
  }
}
