import ApplicationController from "../application_controller.js";

export default class extends ApplicationController {
  static targets = ["input", "label"];
  connect() {
    super.connect();
    console.log(this.hasInputTarget );
    if (this.hasInputTarget ) {
      console.log(this.inputTarget);
        this.inputTarget.addEventListener("change", (e) => this.change_input_text_content())
    }
    if (this.hasLabelTarget ) {
        this.labelTarget.addEventListener("click", (e) => this.reset_input(e))
    }
  }

  change_input_text_content() {
    var filename = this.inputTarget.files.length > 1 ? `X  ${this.inputTarget.files.length} קבצים` : 'X ' + this.inputTarget.files[0].name;
    this.labelTarget.textContent = filename
  }

  reset_input(e) {
    if (this.inputTarget.value) e.preventDefault()
    this.inputTarget.value = null
    this.labelTarget.textContent = "העלאת קובץ מהמחשב"
  }

}
