import ApplicationController from "./application_controller.js";

export default class extends ApplicationController {
  delete_row(e) {
    e.preventDefault();
    if (!confirm("בטוח/ה?")) return;
    const row = e.target.closest("tr, .js-item-row");
    const d = row.querySelector('input[name*="_destroy"]');
    if (d) {
      d.value = 1;
      row.style.display = "none";

      document.querySelector(".app-status").textContent =
        "יש שינויים שלא נשמרו";

      document.querySelector(".bottom-footer").classList.toggle("dirty", true);
    }
  }
}
