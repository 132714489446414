import ApplicationController from "./application_controller.js";

export default class extends ApplicationController {
  connect() {
    super.connect();
    // console.log("notification connected");

    const textarea = document.querySelector(".notifications-send textarea");
    if (textarea) {
      textarea.onkeypress = function (e) {
        if (!e) e = window.event;
        var keyCode = e.code || e.key;
        if (keyCode == "Enter") {
          // console.log(e);
          document.querySelector(".notifications-send button.js-send").click();
          // Enter pressed
          return false;
        }
      };
      textarea.onfocus = function (e) {
        // console.log("focus");

        document.querySelector(".notifications-send").classList.add("hover");
      };
      textarea.onblur = function (e) {
        document.querySelector(".notifications-send").classList.remove("hover");
      };

      const file = document.querySelector(
        "#conversation_messages_attributes_0_file"
      );
      file.addEventListener("change", (e) => {
        // Get the selected file
        const [file] = e.target.files;
        // Get the file name and size
        const { name: fileName, size } = file;
        // Convert size in bytes to kilo bytes
        const fileSize = (size / 1000).toFixed(2);
        // Set the text content
        const fileNameAndSize = `${fileName.split(".")[0]} 
      - ${fileSize}KB`;
        const div = e.target.closest("div");
        div.querySelector("label").setAttribute("title", fileNameAndSize);
        div.querySelector("i").textContent = "file_present";
        const p = document.querySelector(".file-upload-js");
        p.classList.remove("hide");
        p.querySelector(".title").textContent = fileNameAndSize;
      });
    }
    document.getElementById("notifications-masseges").onclick = (e) => {
      if (e.target.closest(".massege")) return;
      this.close_msg_options(e);
    };
  }

  open_serach(e) {
    this.toggle_serach(e);
  }
  close_serach(e) {
    this.toggle_serach(e);
  }

  toggle_serach(e) {
    const searchbar = document.querySelector(".notifications-searchbar");
    const input = searchbar.querySelector("input");
    const header = document.querySelector(".notifications-header");
    const tags = searchbar.querySelector(".notifications-searchbar-page-tags");
    searchbar.querySelector(".open").classList.toggle("hide");
    searchbar.querySelector(".close").classList.toggle("hide");
    input.classList.toggle("hide");
    tags.classList.toggle("hide");
    header.querySelector(".title").classList.toggle("hide");
    input.value = "";
    input.setAttribute("data-tag", "");
    if (!input.classList.contains("hide")) input.focus();
    this.search();
  }
  search(e) {
    const input = document.querySelector(".notifications-searchbar input");
    let query = input.value;
    let tag = input.dataset.tag;
    if (tag == "") tag = null;
    if (query == "") query = null;
    const msgsContainer = document.querySelector(".notifications-masseges");
    const msgs = msgsContainer.querySelectorAll(".massege");
    const conversations = document.querySelectorAll(
      ".notifications-groups .conversation"
    );
    // console.log(msgs, tag);
    msgs.forEach((msg) => {
      const text = msg.dataset.text;
      msg.classList.toggle(
        "hide",
        !(
          (query &&
            tag &&
            text.indexOf(query) >= 0 &&
            text.indexOf(tag) >= 0) ||
          (!query && tag && text.indexOf(tag) >= 0) ||
          (query && !tag && text.indexOf(query) >= 0) ||
          (!query && !tag)
        )
      );
    });
    conversations.forEach((con) => {
      const text = con.dataset.text;
      con.classList.toggle("hide", query && text.indexOf(query) < 0);
    });
  }

  search_tag(e) {
    const input = document.querySelector(".notifications-searchbar input");
    // console.log(e.currentTarget.dataset.tag);
    const tags = document
      .querySelector(".notifications-searchbar-page-tags")
      .querySelectorAll(".btn");
    // console.log(tags);
    tags.forEach((b) => {
      b.classList.toggle(
        "selected",
        e.currentTarget == b && !b.classList.contains("selected")
      );
    });
    if (e.currentTarget.classList.contains("selected")) {
      input.setAttribute("data-tag", e.currentTarget.dataset.tag);
    } else {
      input.setAttribute("data-tag", "");
    }
    this.search(e);
  }

  remove_page(e) {
    e.currentTarget.classList.add("hide");
    const form = e.currentTarget.closest(".notifications-send");
    form.querySelector(".js-page").value = "";
  }
  remove_msg(e) {
    const newMsgWrapper = document.querySelector(
      ".notifications-send .js-replay-msg-wrapper"
    );
    const newMsgInput = document.querySelector(".notifications-send .js-msg");
    newMsgWrapper.classList.add("hide");
    newMsgInput.value = "";
  }

  toggle_msg_options(e) {
    const wrapper = e.currentTarget.closest(".message-wrapper");
    if (!wrapper) {
      this.scroll_to_msg(e);
      return;
    }
    this.close_msg_options(e);
    wrapper.querySelector(".message-options").classList.toggle("hide");
  }
  close_msg_options(e) {
    const msgs = document.querySelectorAll(".message-wrapper");
    msgs.forEach((msg) => {
      msg.querySelector(".message-options").classList.add("hide");
    });
  }

  reply(e) {
    const msgId = e.currentTarget.dataset.msg;
    const oldMsg = e.currentTarget
      .closest(".message-wrapper")
      .querySelector(".massege");
    const newMsgInput = document.querySelector(".notifications-send .js-msg");
    const newMsgWrapper = document.querySelector(
      ".notifications-send .js-replay-msg-wrapper"
    );
    const newMsgContainer = newMsgWrapper.querySelector(".replay-msg");
    // console.log(oldMsg);
    newMsgContainer.innerHTML = "";
    newMsgWrapper.classList.remove("hide");
    newMsgContainer.appendChild(oldMsg.cloneNode(true));
    newMsgInput.value = msgId;
  }
  scroll_to_msg(e) {
    const msgId = e.currentTarget.dataset.msg;
    const msg = document.querySelector(
      ".notifications-masseges .massege[data-msg='" + msgId + "']"
    );

    // console.log(msg);

    msg.scrollIntoView({ block: "nearest", inline: "nearest" });

    msg.classList.add("pulse");
    setTimeout(() => {
      msg.classList.remove("pulse");
    }, 3000);
  }

  delete_msg(e) {
    const wrapper = e.currentTarget.closest(".message-wrapper");
    const msgId = wrapper.querySelector(".massege").dataset.msg;
    wrapper.classList.add("hide");
    this.stimulate("notifications#delete_msg", msgId);
  }
  toggle_notifications(e) {
    const state = document.querySelector(".notifications-panel").classList.contains("close")
    console.log(state);

    this.stimulate(
      "notifications#toggle_notifications",
      !state
    ).then()
    
  }
  remove_file() {
    const p = document.querySelector(".file-upload-js");
    p.classList.add("hide");
    const f = document.querySelector(
      "#conversation_messages_attributes_0_file"
    );
    f.files = null;
    const div = f.closest("div");
    div.querySelector("label").setAttribute("title", "בחרו קובץ");
    div.querySelector("i").textContent = "attach_file";
  }
}
