import ApplicationController from "./application_controller.js";

export default class extends ApplicationController {
  toggle_price_info(e) {
    const viewWrapper = e.currentTarget
      .closest(".js-view")
      .classList.toggle("show-price-info");
    e.currentTarget.textContent = e.currentTarget.textContent.includes("הצג")
      ? "הסתר מחיר"
      : "הצג מחיר";
  }

  change_view(e) {
    e.preventDefault();
    const views = Array.from(
      e.currentTarget.closest(".js-view").querySelectorAll(".viewType-js")
    );
    const buttons = Array.from(
      e.currentTarget.closest(".js-view").querySelectorAll(".viewButton-js")
    );
    const type = e.currentTarget.value;
    views.forEach((view) => {
      view.classList.toggle("hide", !view.classList.contains(type));
    });
    buttons.forEach((btn) => {
      btn.classList.toggle("hide", btn.value == type);
    });
  }
}
