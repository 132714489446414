import ApplicationController from "../application_controller.js";
import * as $ from "jquery";

require("datatables.net-dt");

export default class extends ApplicationController {
    static targets = ["table"];

    connect() {
    if (this.hasTableTarget) {
        console.log(this.tableTarget.id);

        $(`#${this.tableTarget.id}`).DataTable({stateSave: true,
            orderCellsTop: true,
            fixedHeader: true})
    }

    }
}