import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "template", "btn"];

  connect() {
    document
      .getElementById("activity_general_coord_files")
      .addEventListener("change", (event) => {
        var inp = event.target;
        for (var i = 0; i < inp.files.length; ++i) {
          var name = inp.files.item(i).name;
          const div = document.createElement("div");
          div.className = "tag";
          div.textContent = name;
          document
            .getElementById("new_activity_general_coord_files")
            .appendChild(div);
        }
      });
  }

  change_file(e) {
    e.target
      .closest(".coord-file-div")
      .querySelector(".file-input")
      .classList.add("show");
    e.target.style.display = "none";
  }

  open_file_cooser(e) {
    // console.log(e);
    document.getElementById("activity_general_coord_files").click();
  }
}
