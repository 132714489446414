import ApplicationController from "./application_controller.js";

export default class extends ApplicationController {
  connect() {
    // console.log("calendar");
  }
  hover_in(e) {
    const target = e.currentTarget;
    const mifal = target.getAttribute("mifal");
    document.querySelectorAll('[mifal="' + mifal + '"').forEach((e) => {
      e.classList.add("hover");
    });
  }
  hover_out(e) {
    const target = e.currentTarget;
    const mifal = target.getAttribute("mifal");
    document.querySelectorAll('[mifal="' + mifal + '"').forEach((e) => {
      e.classList.remove("hover");
    });
  }
  go_to_activity(e) {
    const target = e.currentTarget;
    const url = target.dataset.url;
    window.location.href = url;
  }

  jump_to_date(e) {
    const date = e.target.value + "-01";
    const url = new URL(window.location.href);
    // console.log(url);
    url.searchParams.set("start_date", date);
    window.location.href = url;
  }
}
