import ApplicationController from "./application_controller.js";

export default class extends ApplicationController {
  show_activity(e) {
    const activity_id = e.currentTarget.dataset.id;

    const element = document.querySelector(
      `[activity="${activity_id}"]`
    );
    var myDiv = element.closest(".cards");
    myDiv.scrollTop = 0;
    document.querySelectorAll("[activity]").forEach((el) => {
      el.classList.remove("highlight-alt");
      el.style.order = 2;
    });
    element.classList.add("highlight-alt");
    setTimeout(() => {
      element.classList.remove("highlight-alt");
    }, 6000);
    element.style.order = 1;
    console.log(activity_id)
    this.stimulate("View#show_activity", activity_id)
  }
}
