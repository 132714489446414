import ApplicationController from "./application_controller.js";

export default class extends ApplicationController {
  connect() {
    super.connect();

    const connetionIcon = document.getElementById("conIcon");
    const connetionText = document.getElementById("conText");
    function setConnectionStatus(isCon) {
      if (isCon) {
        connetionIcon.innerHTML = "public";
        connetionText.innerHTML = "מחובר";
        connetionIcon.closest("div").style.background = "transparent";
        connetionIcon.closest("div").title = "מחובר לאינטרנט";
      } else {
        connetionIcon.innerHTML = "block";
        connetionText.innerHTML = "לא מחובר";
        connetionIcon.closest("div").style.background = "indianred";
        connetionIcon.closest("div").title =
          "לא מחובר לאינטרנט - שימו לב, נדרש חיבור לצורך שימוש בתכנה";
      }
    }
    // console.log(this.isActionCableConnectionOpen());
    setConnectionStatus(
      window.navigator.onLine && this.isActionCableConnectionOpen()
    );

    window.addEventListener("online", () =>
      setConnectionStatus(this.isActionCableConnectionOpen())
    );
    window.addEventListener("offline", () => setConnectionStatus(false));

    document.addEventListener("stimulus-reflex:connected", () =>
      setConnectionStatus(window.navigator.onLine)
    );

    document.addEventListener("stimulus-reflex:disconnected", () =>
      setConnectionStatus(false)
    );
  }
}
