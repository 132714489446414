import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "tableWrapper",
    "table",
    "tableBar",
    "colToFilter",
    "selectable",
  ];
  connect() {
    super.connect();

    console.log("table");

    if (this.hasColToFilterTarget) {
      const cols = this.tableWrapperTarget.querySelectorAll("thead th");
      this.colToFilterTarget.innerHTML = "";
      const opt = document.createElement("option");
      opt.value = -1;
      opt.innerHTML = "הכל";
      opt.selected = true;
      this.colToFilterTarget.appendChild(opt);

      cols.forEach((col, i) => {
        const opt = document.createElement("option");
        opt.value = i;
        opt.innerHTML = col.textContent;
        this.colToFilterTarget.appendChild(opt);
      });
    }
    if (this.hasSelectableTarget) {
      const checkbox = document.createElement("INPUT");
      checkbox.setAttribute("type", "checkbox");
      this.tableWrapperTarget
        .querySelectorAll("thead th")[0]
        .appendChild(checkbox);
      this.tableWrapperTarget.addEventListener(
        "click",
        (e) => {
          const tagName = e.target.tagName;
          if (
            tagName == "INPUT" ||
            tagName == "BUTTON" ||
            tagName == "TEXTAREA"
          )
            return;
          const tr = e.target.closest("tr");
          if (tr && tr.classList.contains("selectable")) {
            // console.log(tr);
            this.row_selected({ target: tr, currentTarget: tr });
          }
        },
        false
      );
      checkbox.addEventListener("change", (e) => this.toggle_selection_all(e));
      this.set_selectable_target();
    }
  }

  toggle_selection_all(e) {
    const checked = e.currentTarget.checked;
    this.itirate_rows((row) => row.classList.toggle("selected", checked));
    this.only_visible_row_selected();
    this.set_selectable_target();
  }

  get_rows(filter = "") {
    return this.tableWrapperTarget.querySelectorAll(
      `tbody tr.selectable${filter ? "." + filter : ""}`
    );
  }

  itirate_rows(fn, filter = "") {
    this.get_rows(filter).forEach((row, i) => {
      fn(row);
    });
  }
  get_html_for_selectable(selected) {
    const span = "<span>נבחרו: " + selected + " </span>";
    const deleteButton = this.selectableTarget.classList.contains("no-delete") ? "" :
      "<button type='button' class='btn-flat delete-btn' data-action='table#delete_selected'><i class='material-symbols-outlined'>delete</i></button>";
    const editButton = this.selectableTarget.classList.contains("no-edit") ? "" :
      "<button type='button' class='regular-btn btn-flat mr-1' data-action='table#edit_rows'><i class='material-symbols-outlined'>edit</i></button>";
    return (
      "<div class='flex align-center selectable-target'>" +
      span +
      editButton +
      deleteButton +
      "</div>"
    );
  }
  delete_selected(e) {
    // console.log("delete");
    if (!confirm("בטוח/ה?")) return;
    const fn = (row) => {
      const d = row.querySelector('input[name*="_destroy"]');
      if (d) {
        d.value = 1;
        row.style.display = "none";
      }
    };
    this.itirate_rows(fn, "selected");

    document.querySelector(".app-status").textContent = "יש שינויים שלא נשמרו";
    document.querySelector(".bottom-footer").classList.toggle("dirty", true);
  }

  set_selectable_target() {
    const selected = this.get_rows("selected").length;
    this.selectableTarget.innerHTML = ``;
    if (selected == 0) return;
    const html = this.get_html_for_selectable(selected);
    this.selectableTarget.innerHTML = html;
  }

  only_visible_row_selected() {
    const fn = (row) => {
      row.classList.toggle("selected", row.style.display != "none");
      row.classList.toggle(
        "selected-but-not-visible",
        row.style.display == "none"
      );
    };
    this.itirate_rows(fn, "selected");

    this.itirate_rows(fn, "selected-but-not-visible");
    this.set_selectable_target();
  }

  row_selected(e) {
    const tagName = e.target.tagName;
    if (tagName == "INPUT" || tagName == "BUTTON") return;
    e.currentTarget.classList.toggle(
      "selected",
      !e.currentTarget.classList.contains("selected")
    );
    this.set_selectable_target();
  }

  edit_rows() {
    const header = this.tableTarget.querySelector("thead tr");
    const rows = this.get_rows("selected");
    const row = rows[0];
    const inputs = row.querySelectorAll(
      "INPUT:not(.hidden):not([type='hidden'])"
    );
    const textareas = row.querySelectorAll(
      "TEXTAREA:not(.hidden):not([type='hidden'])"
    );
    const tds = row.querySelectorAll("td");
    const data = [];
    // console.log(inputs);
    [...inputs, ...textareas].forEach((input) => {
      const td = input.closest("td");
      if (td) {
        const head =
          header.querySelectorAll("th")[[...tds].indexOf(td)].textContent;
        data.push({
          element: input,
          header: head,
          type: input.type,
          classes: input.className,
        });
      }
    });
    this.open_edit_modal(data, rows);
  }
  open_edit_modal(inputs, rows) {
    const modal = document.querySelector(
      `#edit_selected_records-modal.modal-wrapper`
    );
    modal.classList.remove("hide");
    if (modal.querySelector(".inputsWrapper")) return;

    const parent = document.createElement("div");
    const sub1 = document.createElement("strong");
    const sub2 = document.createElement("cite");

    sub1.textContent = `נבחרו ${rows.length} רשומות`;
    sub2.textContent = "עריכת כל הרשומות שנבחרו, שדות שישארו ריקים לא ישונו";
    parent.className = "flex column gap-1 inputsWrapper";
    parent.appendChild(sub1);
    parent.appendChild(sub2);

    const buttons = modal.querySelector(".modal-buttons");
    const btn = document.createElement("button");
    btn.type = "button";
    btn.className = "btn";
    btn.textContent = "שמור";
    buttons.appendChild(btn);
    inputs.forEach((input) => {
      const div = document.createElement("div");
      div.className = "flex";
      const span = document.createElement("strong");
      span.style.width = "50%";
      span.style.alignSelf = "center";
      span.textContent = input.header + ":";
      const input_box = document.createElement("input");
      input_box.style.width = "50%";
      input_box.className = input.classes;
      input_box.type = input.type;
      div.appendChild(span);
      div.appendChild(input_box);
      parent.appendChild(div);
    });
    const fn = (event) => this.save_edit_rows(inputs);
    btn.addEventListener("click", fn);

    modal.querySelector("article").appendChild(parent);
  }

  save_edit_rows(inputs) {
    // console.log(inputs);
    const modal = document.querySelector(
      `#edit_selected_records-modal.modal-wrapper`
    );

    inputs.forEach((input) => {
      input.toChange = modal.getElementsByClassName(input.classes)[0].value;
      modal.getElementsByClassName(input.classes)[0].value = null;
    });
    const edit = inputs.filter((input) => input.toChange);
    const fn = (row) => {
      edit.forEach((input) => {
        row.getElementsByClassName(input.classes)[0].value = input.toChange;
        row
          .getElementsByClassName(input.classes)[0]
          .dispatchEvent(new Event("input"));
      });
    };
    this.itirate_rows(fn, "selected");
    modal.classList.add("hide");
  }

  tableToCSV(e) {
    e.preventDefault();
    // Variable to store the final csv data
    var csv_data = [];

    // Get each row data
    var rows = this.tableTarget.querySelectorAll("tr");
    for (var i = 0; i < rows.length; i++) {
      // Get each column data
      var cols = rows[i].querySelectorAll("td,th");

      // Stores each csv row data
      var csvrow = [];
      for (var j = 0; j < cols.length; j++) {
        // Get the text data of each cell
        // of a row and push it to csvrow
        csvrow.push(this.checkForText(cols[j]));
      }

      // Combine each column value with comma
      csv_data.push(csvrow.join(","));
    }

    // Combine each row data with new line character
    csv_data = csv_data.join("\n");

    // add UTF-8 BOM to beginning so excel doesn't get confused.
    const BOM = String.fromCharCode(0xfeff);
    csv_data = BOM + csv_data;
    // Call this function to download csv file
    this.downloadCSVFile(csv_data, e.currentTarget.dataset.name);
  }

  downloadCSVFile(csv_data, file_name) {
    // Create CSV file object and feed
    // our csv_data into it
    const CSVFile = new Blob([csv_data], {
      type: "text/csv;charset=UTF-8",
    });

    // Create to temporary link to initiate
    // download process
    var temp_link = document.createElement("a");

    // Download csv file
    temp_link.download = "exported " + file_name + ".csv";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    // Automatically click the link to
    // trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
  }

  search(e) {
    // Declare variables
    var input, filter, table, tr, td, i, n, txtValue, col;
    input = e.target;
    filter = input.value.toUpperCase();
    table = this.tableTarget;
    const rows = Array.from(
      table.querySelectorAll(":scope > tbody > tr:not(.table__row-noItems)")
    );
    tr = table.getElementsByTagName("tr");
    col = this.colToFilterTarget.value;
    rows.forEach((row) => {
      row.style.display = "none";
    });

    const filteredRows = rows.filter((row) => {
      txtValue = "";
      const cellsTextArr = Array.from(row.cells).map((cell) =>
        this.checkForText(cell)
      );
      // console.log(cellsTextArr);

      if (col == -1) {
        txtValue = cellsTextArr.join(" ");
        // console.log(txtValue);
      } else {
        txtValue = cellsTextArr[col];
      }
      return txtValue == "" || txtValue.toUpperCase().indexOf(filter) > -1;
    });
    filteredRows.forEach((row) => {
      row.style.display = "";
    });

    for (let row of rows) {
      table.tBodies[0].appendChild(row);
    }
    this.only_visible_row_selected();
  }

  checkForText(td) {
    if (!td || td.className == "td-actions") return "";
    // console.log(td.dataset);

    if (td.dataset && td.dataset.text) return td.dataset.text;
    if (td.querySelector("input[type=checkbox]"))
      return td.querySelector("input[type=checkbox]").checked ? "1" : "0";
    if (td.querySelector("input")) return td.querySelector("input").value;
    if (td.querySelector("textarea")) return td.querySelector("textarea").value;
    if (td.querySelector("select"))
      return td.querySelector("select").options[
        td.querySelector("select").selectedIndex
      ].text;
    return td.innerText;
  }

  // This code has TypeScript type annotations, but can be used directly as pure JavaScript by just removing the type annotations first.

  sortTableRowsByColumn(table, columnIndex, ascending) {
    const rows = Array.from(
      table.querySelectorAll(":scope > tbody > tr:not(.table__row-noItems)")
    );

    rows.sort((x, y) => {
      const xValue = this.checkForText(x.cells[columnIndex]);
      const yValue = this.checkForText(y.cells[columnIndex]);

      const xNum = parseFloat(xValue);
      const yNum = parseFloat(yValue);

      if (isNaN(xNum) || isNaN(yNum)) {
        return ascending
          ? xValue.localeCompare(yValue)
          : yValue.localeCompare(xValue);
        // <-- Neat comparison trick.
      }
      return ascending ? yNum - xNum : xNum - yNum; // <-- Neat comparison trick.
    });

    // There is no need to remove the rows prior to adding them in-order because `.appendChild` will relocate existing nodes.
    for (let row of rows) {
      table.tBodies[0].appendChild(row);
    }
  }

  onColumnHeaderClicked(ev) {
    const th = ev.currentTarget;
    const table = th.closest("table");
    const thIndex = Array.from(th.parentElement.children).indexOf(th);

    const ascending = th.dataset.sort != "asc";

    this.sortTableRowsByColumn(table, thIndex, ascending);
    const allTh = table.querySelectorAll(":scope > thead > tr > th");
    for (let th2 of allTh) {
      delete th2.dataset["sort"];
      th2.classList.remove("asc");
      th2.classList.remove("desc");
    }
    th.dataset.sort = ascending ? "asc" : "desc";
    th.classList.toggle("asc", ascending);
    th.classList.toggle("desc", !ascending);
  }
  
  filter_category(e) {
    const table = document.querySelector(".table.viewType-js").querySelector("table");
  const trs = Array.from(table.querySelector("tbody").querySelectorAll("tr"));
  const category = e.currentTarget.value;
  // console.log(category);
  trs.forEach((tr) => {
    if (
      category == "all" ||
      !tr.dataset.category ||
      tr.dataset.category == category
      )
      tr.style.display = "table-row";
      else tr.style.display = "none";
  });
}
}

/*
sortTable(n, cols) {
    cols.forEach(col => {
        col.classList.remove('asc')
        col.classList.remove('desc')

    })
    var table, rows, switching, i, x, y,textX, textY, shouldSwitch, dir, switchcount = 0;
    table = this.tableTarget;
    switching = true;
    // Set the sorting direction to ascending:
    dir = "asc";
    /* Make a loop that will continue until
    no switching has been done: */
/*
    while (switching) {
      // Start by saying: no switching is done:
      switching = false;
      rows = table.rows;
      /* Loop through all table rows (except the
      first, which contains table headers): */
/*
      let rowStart = this.isSortable(rows[1])  ? 1 : 2
      for (i = rowStart; i < (rows.length - 1); i++) {
        // Start by saying there should be no switching:
        shouldSwitch = false;

        
        /* Get the two elements you want to compare,
        one from current row and one from the next: */
/*
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];

        textX = this.checkForText(x)
        textY = this.checkForText(y)
        
        // console.log(textX)
        // console.log(textY)
        /* Check if the two rows should switch place,
        based on the direction, asc or desc: */
/*
        if (dir == "asc") {

          if (textX.toLowerCase() > textY.toLowerCase()) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true ;
            break;
          }
        } else if (dir == "desc") {

          if (textX.toLowerCase() < textY.toLowerCase()) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        /* If a switch has been marked, make the switch
        and mark that a switch has been done: */
/*
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        // Each time a switch is done, increase this count by 1:
        switchcount ++;
      } else {
        /* If no switching has been done AND the direction is "asc",
        set the direction to "desc" and run the while loop again. */
/*
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
      cols[n].classList.add(dir)

    }
  }
*/
