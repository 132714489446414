import $ from "jquery";

import Select2 from "select2";
import ApplicationController from "./application_controller.js";

export default class extends ApplicationController {
  static targets = ["add", "template"];
  static values = { index: Number };

  connect() {
    super.connect();
    const first_nested_form = document.querySelectorAll(".nested-staffs")[0];
    let destroy = first_nested_form.querySelector(`[id*='destroy']`);
    let btn = first_nested_form.querySelector("button");
    destroy.remove();
    btn.remove();
    document.getElementById("staffs").addEventListener("change", (e) => {
      // console.log(e.target.classList);

      if (
        !e.target.classList.contains("email") ||
        !document.querySelector("#activity_user_ids")
      )
        return;
      this.find_user_by_mail(e.target);
    });
    // this.correct_inputs()
  }

  add_association(event) {
    event.preventDefault();
    var content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    );
    const added = this.addTarget.insertAdjacentHTML("beforebegin", content);
    $(".select2-tags").select2();
  }

  remove_association(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-staffs");
    item.querySelector("input[name*='_destroy']").value = true;
    item.querySelector("input[name*='name']").remove();
    item.querySelector("input[name*='phone']").remove();
    item.querySelector("input[name*='email']").remove();
    item.style.display = "none";
  }

  find_user_by_mail(target) {
    const email = target.value;
    const btn_target = target
      .closest(".nested-staffs")
      .querySelector(".add-access").id;
    const activity = target.dataset.activity;
    const current_selected = this.getSelectValues(
      document.querySelector("#activity_user_ids")
    );
    this.stimulate(
      "staffs#find_user_by_mail",
      email,
      btn_target,
      current_selected,
      activity
    );
    // console.log(email, btn_target);
  }

  getSelectValues(select) {
    var result = [];
    var options = select && select.options;
    var opt;

    for (var i = 0, iLen = options.length; i < iLen; i++) {
      opt = options[i];

      if (opt.selected) {
        result.push(opt.value || opt.text);
      }
    }
    return result;
  }

  // correct_inputs(){
  // 	const staffs_attributes = ['role', 'name', 'phone', 'email', '_destroy']
  // 	const last_nested_form = document.querySelectorAll(".nested-staffs")[document.querySelectorAll(".nested-staffs").length - 1 ]
  // 	for (var i = 0; i < staffs_attributes.length; i++) {
  // 		let input = last_nested_form.querySelector(`[id*='${staffs_attributes[i]}']`)
  // 		let label = last_nested_form.querySelector(`[for*='${staffs_attributes[i]}']`)
  // 		input.name = `activity[staffs_attributes][${this.indexValue}][${staffs_attributes[i]}]`
  // 		input.id = `activity_staffs_attributes_${staffs_attributes[i]}_${this.indexValue}`
  // 		label.for = `activity_staffs_attributes_${this.indexValue}_${staffs_attributes[i]}`
  // 	}
  // }
}
