import ApplicationController from "./application_controller.js";
import $ from "jquery";

export default class extends ApplicationController {
  connect() {
    // console.log("modal connect");
    document.onkeydown = function (evt) {
      evt = evt || window.event;
      if (evt.key === "Escape") {
        document.querySelectorAll(".modal-wrapper").forEach((modal) => {
          modal.classList.add("hide");
        });
      }
    };
  }
  open(e) {
    e.preventDefault();
    const target = e.currentTarget;
    const modalId = target.dataset.modal;
    document
      .querySelector(`#${modalId}-modal.modal-wrapper`)
      .classList.remove("hide");
    if (target.dataset.noLoad == "true") return;

    document.querySelector(`#${modalId}-modal .modal-content`).innerHTML =
      "טוען נתונים...";
  }
}
