import ApplicationController from "./application_controller.js";

export default class extends ApplicationController {
  static targets = ["activity"];
  connect() {
    super.connect();
    let activity = null;
    if (this.hasActivityTarget) activity = this.activityTarget.dataset.activity;
    if (this.isActionCableConnectionOpen())
      this.stimulate("notifications#reload_notifications", activity);
  }
}
