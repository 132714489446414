import ApplicationController from "./application_controller.js";
import $ from "jquery";

import Select2 from "select2";
// import { Controller } from "stimulus"

export default class extends ApplicationController {
  static targets = [
    "add",
    "heads_template",
    "fields",
    "guides_template",
    "heads",
    "guides",
    "kids",
    "addHeads",
    "addGuides",
    "headsForm",
    "guidesForm",
  ];
  // static values = { prep: Number }

  add_prep(event) {
    if (event.target.value == "yes") {
      this.add_association(event.target.dataset.prepvalue);
    } else {
      this.remove_association(event.target.dataset.prepvalue);
    }
  }

  //
  add_association(prepvalue) {
    let content, add, form;
    // console.log(prepvalue);

    if (this[`has${super.capitalize(prepvalue)}FormTarget`]) {
      form = this[`${prepvalue}FormTarget`];
      this[`${prepvalue}Targets`].forEach((element) => {
        element.classList.add("show");
      });
      form.querySelector("input[name*='_destroy']").value = false;
      form.classList.add("show");
      form.querySelector("input[name*='start_date']").required = true;
      form.querySelector("input[name*='end_date']").required = true;
    } else {
      add = this[`add${super.capitalize(prepvalue)}Target`];
      content = this[`${prepvalue}_templateTarget`].innerHTML;
      add.classList.add("show");
      add.innerHTML = content;
    }

    // if (prepvalue == 'heads') {
    //     if (this.hasHeadsFormTarget) {
    //         // item = this.fieldsTarget.querySelector(`input[name*='mifal_type'][value*='${prepvalue}']`).closest(".nested-mifals")
    //         item = this.headsFormTarget
    //         item.querySelector("input[name*='_destroy']").value = false
    //         this.addHeadsTarget.classList.add("show")
    //             // item.style.display = "initial"
    //         item.querySelector("input[name*='start_date']").required = true
    //         item.querySelector("input[name*='end_date']").required = true
    //     } else {
    //         content = this.heads_templateTarget.innerHTML
    //         this.addHeadsTarget.classList.add("show")
    //             // this.addHeadsTarget.insertAdjacentHTML('beforebegin', content)
    //         this.addHeadsTarget.innerHTML = content

    //     }
    // } else {
    //     if (this.hasGuidesFormTarget) {
    //         // item = this.fieldsTarget.querySelector(`input[name*='mifal_type'][value*='${prepvalue}']`).closest(".nested-mifals")
    //         item = this.guidesFormTarget
    //         item.querySelector("input[name*='_destroy']").value = false
    //         this.addGuidesTarget.classList.add("show")
    //             // item.style.display = "initial"
    //         item.querySelector("input[name*='start_date']").required = true
    //         item.querySelector("input[name*='end_date']").required = true
    //     } else {
    //         content = this.guides_templateTarget.innerHTML
    //             // this.addGuidesTarget.insertAdjacentHTML('beforebegin', content)
    //         this.addGuidesTarget.classList.add("show")
    //         this.addGuidesTarget.innerHTML = content
    //     }

    // }
    // if (content != undefined) {
    //     // const added = this.addTarget.insertAdjacentHTML('beforebegin', content)
    // }

    // this.indexValue++
    // this.correct_inputs()
    $(".select2-tags").select2({
      placeholder: "בחרו כמה...",
      tags: true,
    });
  }

  remove_association(prepvalue) {
    let form;

    // console.log(prepvalue);
    // item = this.fieldsTarget.querySelector(`input[name*='mifal_type'][value*='${prepvalue}']`).closest(".nested-mifals")
    form = this[`${prepvalue}FormTarget`];
    form.querySelector("input[name*='_destroy']").value = true;
    form.querySelector("input[name*='start_date']").required = false;
    form.querySelector("input[name*='end_date']").required = false;
    // item.querySelector("input[name*='[id]']").value = ''
    // item.style.display = "none"
    this[`${prepvalue}Targets`].forEach((element) => {
      element.classList.remove("show");
    });
    // if (prepvalue == 'heads') {
    //     this.headsTargets.forEach(element => {
    //         element.classList.remove("show")

    //     });
    // } else {
    //     this.guidesTargets.forEach(element => {
    //         element.classList.remove("show")

    //     });

    // }
  }

  length(e) {
    const fields = e.target.closest(".nested-mifals");
    const mifal = e.target.dataset.mifal;
    const length = fields.querySelector("input[name*='length']");
    const start = new Date(
      fields.querySelector("input[name*='start_date']").value
    );
    const end = new Date(fields.querySelector("input[name*='end_date']").value);
    const notice = fields.querySelector(".notice");
    const difference = end - start;
    const days = difference / (1000 * 3600 * 24) + 1;
    // console.log(fields, mifal, days);
    // console.log(notice, difference, length);

    if (days <= 0) {
    } else {
      length.value = days;
    }
  }
}
