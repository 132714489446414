import ApplicationController from "./application_controller.js";

import Select2 from "select2";

export default class extends ApplicationController {
  static targets = ["menu1", "menu2", "menu3"];

  update_menus() {
    const menu1 = this.menu1Target;
    const mifal1 = menu1.dataset.trialsMifal1;
    if (this.hasMenu3Target) {
      const menu2 = this.menu2Target;
      const mifal2 = menu2.dataset.trialsMifal2;
      const menu3 = this.menu3Target;
      // console.log(menu3);
      const mifal3 = menu3.dataset.trialsMifal3;
      // console.log(mifal3);
      this.stimulate(
        "Food#update_menus",
        [mifal1, mifal2, mifal3],
        [menu1.value, menu2.value, menu3.value]
      );
    } else if (this.hasMenu2Target) {
      const menu2 = this.menu2Target;
      const mifal2 = menu2.dataset.trialsMifal2;
      this.stimulate(
        "Food#update_menus",
        [mifal1, mifal2],
        [menu1.value, menu2.value]
      );
    } else {
      this.stimulate("Food#update_menus", [mifal1], [menu1.value]);
    }
  }

  // set_quantity(e){
  // 	const quantity = e.target.value
  // 	const mf_id = e.target.dataset.mf
  // 	this.stimulate('Food#set_quantity', mf_id, quantity)
  // }
}
